import { useState } from "react";
import { Message } from "../Message";
import useInterval from "use-interval";
import type { MiniBannerMessage } from "@xxl/content-api";
import type { NullableOnOptionalDeep } from "../../../global";
import { RotatingMessageContainer } from "../Message/Message.styled";
import { isNullOrUndefined } from "@xxl/common-utils";

type RotatingMessageProps = {
  messages: NullableOnOptionalDeep<MiniBannerMessage>[];
  useFixedWidth: boolean;
};
const RotatingMessage = ({ messages, useFixedWidth }: RotatingMessageProps) => {
  const [activeMessageIndex, setActiveMessageIndex] = useState(0);

  useInterval(() => {
    const nextMessageIndex =
      activeMessageIndex !== messages.length - 1 ? activeMessageIndex + 1 : 0;
    setActiveMessageIndex(nextMessageIndex);
  }, 3000);

  return (
    <>
      {messages.map(({ text, link, color }, index) => (
        <RotatingMessageContainer
          isActiveMessage={activeMessageIndex === index}
          key={index}
        >
          <Message
            text={text}
            link={
              isNullOrUndefined(link)
                ? null
                : (link as { text: string; url: string } | null)
            }
            color={color}
            useFixedWidth={useFixedWidth}
          />
        </RotatingMessageContainer>
      ))}
    </>
  );
};

export { RotatingMessage };
