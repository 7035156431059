import styled from "@emotion/styled/macro";
import { typographyToCss } from "../../../styles/helpers";
import spaces from "../../../styles/theme/spaces";
import { colorsAsCssVariable as colors } from "../../../styles/theme/colors";
import { xxlTheme } from "../../../../src/styles/xxl-theme";

type TColor = { color?: string };

export const RichText = styled.span<TColor>`
  color: ${({ color }) => color ?? xxlTheme.colors.xxlWebBlack};
  a {
    color: inherit;
    text-decoration: underline;
  }
  p {
    margin: 0;
  }
`;

export const Link = styled.a<TColor>`
  ${typographyToCss({
    fontSize: 12,
    fontFamily: "var(--font-family-bold)",
  })}

  display: flex;
  align-items: center;
  text-decoration: none;
  margin-top: ${spaces.line};
  color: ${({ color }) => color ?? colors.xxlBlack};
`;

export const RotatingMessageContainer = styled.div<{
  isActiveMessage: boolean;
}>`
  display: ${({ isActiveMessage }) => (!isActiveMessage ? "none" : "initial")};
`;
