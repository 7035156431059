import React from "react";
import type { ImageData } from "@xxl/frontend-api";
import ConditionalLink from "../ConditionalLink/ConditionalLink";
import { Message } from "./Message";
import type { MiniBannerNonOptional } from "./MiniBanner.helper";
import { Container } from "./MiniBanner.styled";
import { RotatingMessage } from "./RotatingMessage/RotatingMessage";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import { useXxlMediaQuery } from "../../../src/hooks/useXxlMediaQuery";
import { CountDownContainer } from "./Countdown/CountDownContainer";
import { XxlStack } from "../Common/XxlStack";
import spaces from "../../../src/styles/theme/spaces";

export type MiniBannerProps = {
  bannerData: MiniBannerNonOptional;
};

const MiniBanner = ({ bannerData }: MiniBannerProps) => {
  const { messages, backgroundColor, backgroundImage, countdownTimerSettings } =
    bannerData;
  const isSmallScreen = useXxlMediaQuery("MobileAndTabletMediaQuery");
  const hasCountdown =
    isNotNullOrUndefined(countdownTimerSettings) &&
    isNotNullOrUndefined(countdownTimerSettings.date);
  const links = messages.filter((item) => isNotNullOrUndefined(item.link));
  const hasOneLink = links.length === 1;
  const hasMultiMessages = messages.length > 1;
  const hasMultiMessagesOneLink =
    hasMultiMessages && hasOneLink && !hasCountdown;
  const isCountdownBannerLink = hasCountdown && hasOneLink;
  const isBannerLink = hasMultiMessagesOneLink || hasOneLink;
  const firstFoundLink = messages.find((item) =>
    isNotNullOrUndefined(item.link)
  );
  const linkUrl = firstFoundLink?.link?.url ?? "";
  const countDownMsg = messages.find(isNotNullOrUndefined);

  const countdownContent = (
    <XxlStack direction={!isSmallScreen ? "row" : "column"} gap={spaces.mini}>
      <Message
        text={countDownMsg?.text ?? ""}
        link={countDownMsg?.link as { text: string; url: string }}
        color={countDownMsg?.color}
        useFixedWidth={false}
      />
      <CountDownContainer countdownTimerSettings={countdownTimerSettings} />
    </XxlStack>
  );

  const bannerContent = isSmallScreen ? (
    <RotatingMessage messages={messages} useFixedWidth={false} />
  ) : (
    <>
      {messages.map(({ text, link, color }, index) => {
        return (
          <Message
            isInsideALink={isBannerLink}
            key={index}
            text={text}
            link={link as { text: string; url: string }}
            color={color}
            useFixedWidth={true}
          />
        );
      })}
    </>
  );

  return (
    <ConditionalLink
      url={linkUrl}
      condition={isBannerLink || isCountdownBannerLink}
      className="banner"
    >
      <Container
        backgroundColor={backgroundColor?.value ?? null}
        backgroundImageUrl={
          (backgroundImage as ImageData | null)?.baseUrl ?? null
        }
      >
        {!hasCountdown ? bannerContent : countdownContent}
      </Container>
    </ConditionalLink>
  );
};

export { MiniBanner };
