import React from "react";
import { StyledCountdownContainer } from "../MiniBanner.styled";
import type { MiniBannerNonOptional } from "../MiniBanner.helper";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import { useCountdownTimer } from "../../CountdownTimer/hooks/useCountdownTimer";
import { Countdown } from "./Countdown";

type Props = {
  countdownTimerSettings: MiniBannerNonOptional["countdownTimerSettings"];
};
export const CountDownContainer = ({ countdownTimerSettings }: Props) => {
  let endDate = new Date();
  let startDate: Date | undefined;
  let isOnlyHours = false;
  if (isNotNullOrUndefined(countdownTimerSettings)) {
    if (isNotNullOrUndefined(countdownTimerSettings.date)) {
      endDate = new Date(countdownTimerSettings.date);
    }
    if (isNotNullOrUndefined(countdownTimerSettings.isOnlyHours)) {
      isOnlyHours = countdownTimerSettings.isOnlyHours;
    }
    if (isNotNullOrUndefined(countdownTimerSettings.startDate)) {
      startDate = new Date(countdownTimerSettings.startDate);
    } else {
      startDate = undefined;
    }
  }

  const { days, hours, min, sec } = useCountdownTimer({
    endDate,
    isOnlyHours,
    startDate,
  });

  return (
    <StyledCountdownContainer>
      <Countdown
        days={days}
        hours={hours}
        min={min}
        sec={sec}
        isBlackText={countdownTimerSettings?.isBlack ?? false}
      />
    </StyledCountdownContainer>
  );
};
