import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { MQ } from "../../styles/helpers";
import spaces from "../../styles/theme/spaces";
import { colorsAsCssVariable as colors } from "../../styles/theme/colors";

type ContainerProps = {
  backgroundImageUrl: string | null;
  backgroundColor: string | null;
};
export const Container = styled.div<ContainerProps>(
  ({ backgroundImageUrl, backgroundColor }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(${backgroundImageUrl ?? ""});
    background-color: ${backgroundColor ?? colors.xxlWhite};
    background-size: cover;
    padding: ${spaces.smallRegular} ${spaces.almostHuge};
    gap: ${spaces.mini};
    align-items: center;

    ${MQ("tabletHorizontal")} {
      align-items: center;
    }
  `
);

export const StyledCountdownContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: ${MQ("tabletHorizontal")} {

  }
  // TODO: Should be handled ib CountdownTimer
  div > p > span {
    //Timeholder+SpaceHolder
    font-size: 24px;

    &:nth-of-type(odd) {
      //Timeholder
      min-width: 20px;

      ${MQ("tabletHorizontal")} {
        min-width: 28px;
      }
    }
  }
  div > p > span > span:last-of-type {
    //Row
    font-size: 15px;
  }
`;
