import * as React from "react";
import { Link, RichText } from "./Message.styled";
import ReactHtmlParser from "html-react-parser";
import { CaretRight } from "@xxl/icons";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import { XxlStack } from "../../../../src/components/Common/XxlStack";
import { Text } from "../../../../src/components/Text";

type MessageProps = {
  isInsideALink?: boolean;
  useFixedWidth: boolean;
  text: string | null;
  link: {
    text: string;
    url: string;
  } | null;
  color?: string;
};
const Message = ({
  text,
  link,
  color,
  useFixedWidth,
  isInsideALink = false,
}: MessageProps) => {
  const shouldShowLink =
    isNotNullOrUndefined(link) &&
    isNotNullOrUndefined(link.url) &&
    isNotNullOrUndefined(link.text);
  return (
    <XxlStack
      alignItems={"center"}
      justifyContent={"center"}
      textAlign={"center"}
      width={useFixedWidth ? "100%" : "auto"}
    >
      {text !== null && typeof text === "string" && (
        <Text align="center">
          <RichText color={color}>{ReactHtmlParser(text)}</RichText>
        </Text>
      )}
      {shouldShowLink && (
        <Link
          color={color}
          href={isInsideALink ? undefined : link.url}
          as={isInsideALink ? "span" : "a"}
        >
          {link.text}
          <CaretRight fontSize={18} />
        </Link>
      )}
    </XxlStack>
  );
};
export { Message };
