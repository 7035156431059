import React from "react";
import { Stopwatch } from "@xxl/icons";
import { XxlStack } from "../../../../src/components/Common/XxlStack";
import {
  CountDownTimerText,
  CountDownTimerContent,
  Number,
  TimeHolder,
} from "../../../../src/components/CountdownTimer/CountdownTimer.styled";
import spaces from "../../../../src/styles/theme/spaces";
import { useXxlMediaQuery } from "../../../../src/hooks/useXxlMediaQuery";
import { color } from "@xxl/theme";
import type { TextProps } from "../../Text";
import { ensureTwoDigitNumber } from "../../../../src/components/CountdownTimer/CountDownTimer-helper";
import { useTranslations } from "../../../../src/contexts/Translations/TranslationsContext";

type Props = {
  days: number;
  hours: number;
  min: number;
  sec: number;
  isBlackText: boolean;
};
export const Countdown = ({ days, hours, min, sec, isBlackText }: Props) => {
  const { t } = useTranslations();
  const isLaptop = useXxlMediaQuery("LaptopMediaQuery");

  const textProps: TextProps = {
    as: "span",
    fontFamily: "bold",
    size: isLaptop ? "big" : undefined,
  };
  return (
    <CountDownTimerContent>
      <CountDownTimerText isBlackText={isBlackText}>
        <XxlStack
          flexDirection={"row"}
          flexWrap={"wrap"}
          rowGap={spaces.micro}
          alignItems={"center"}
        >
          <XxlStack alignItems={"center"} flexDirection={"row"}>
            <Stopwatch
              fontSize={isLaptop ? 24 : 22}
              color={isBlackText ? color.webBlack.hex : color.white.hex}
            />
            &nbsp;
            {days > 0 && (
              <TimeHolder {...textProps}>
                <Number>{days}</Number>
                {t("countdowntimer.days").charAt(0)}
                &nbsp;:&nbsp;
              </TimeHolder>
            )}
            <TimeHolder {...textProps}>
              <Number>{hours}</Number>
              {t("countdowntimer.hours").charAt(0)}
              &nbsp;:&nbsp;
            </TimeHolder>
            <TimeHolder {...textProps}>
              <Number>{ensureTwoDigitNumber(min)}</Number>
              {t("countdowntimer.min").charAt(0)}
              &nbsp;:&nbsp;
            </TimeHolder>
            <TimeHolder {...textProps}>
              <Number>
                <Number>{ensureTwoDigitNumber(sec)}</Number>
              </Number>
              {t("countdowntimer.sec").charAt(0)}
            </TimeHolder>
          </XxlStack>
        </XxlStack>
      </CountDownTimerText>
    </CountDownTimerContent>
  );
};
